<template>
  <div class="main-body p-0">
    <div class="inner-wrapper">
      <div class="inner-main">
        <page-header :propButtons="page.buttons" :propConfig="page.header" :propModel="dataModel"></page-header>
        <div class="inner-main-body">
          <page-body
            @products="mtd_products"
            :propHeader="dataTable.header"
            :propBody="dataTable.body"
            :propModel="dataModel"
            @action-next-pagination="mtd_nextPagination"
            @action-sonwindow-close="mtd_sonWindowClose"
          >
            <template slot="content-sonwindow">
              <component
                :is="dataTable.body.sonWindow.component"
                :propRow="dataTable.body.sonWindow.row"
                @action-sonwindow-close="mtd_sonWindowClose"
              ></component> </template
          ></page-body>
        </div>
      </div>
    </div>
    <!--Form: create and update-->
    <modal :id="dataModal1.config.id" :propModel="dataModel" :propConfig="dataModal1.config" @action-close="mtd_closeModal1()">
      <component
        :is="dataModal1.slot.component"
        slot="content-modal"
        :propInfo="dataModal1.slot"
        @onload="mtd_componentOnload"
        @success="mtd_componentSuccess"
        @error="mtd_componentError"
        @close="mtd_closeModal1"
      ></component>
    </modal>
  </div>
</template>
<script>
  /** Vuex */
  import { mapActions, mapGetters } from "vuex";
  /** Components */
  import WorkerForm from "../../components/worker/Form";
  /** Page */
  import ProductsPage from "./Products";
  export default {
    components: {
      WorkerForm,
      ProductsPage,
    },
    data() {
      return {
        dataModel: {
          name: "worker", //AQUI EN INGLES SINGULAR
          alias: "Usuarios",
          icon: "fas fa-laptop-code",
        },
        page: {
          header: {
            menu: ["Usuarios", "Lista"],
          },
          buttons: {
            create: false,
            import: false,
            export: false,
          },
        },
        dataTable: {
          header: {
            title: "Lista de trabajadores",
            filter: false,
            page: 1,
            wordsearch: "",
          },
          body: {
            cols: [
              {
                name: "code",
                alias: "Código",
                width: "",
              },
              {
                name: "names",
                alias: "Apellidos y nombres",
                width: "",
              },
              {
                name: "business_name",
                alias: "Empresa",
                width: "",
              },
              {
                name: "charge_name",
                alias: "Cargo",
                width: "",
              },
              {
                name: "email",
                alias: "Correo corp.",
                width: "",
              },
              {
                name: "phone_corporate",
                alias: "Celular corp.",
                width: "",
              },
              {
                name: "state",
                alias: "Estado",
                width: "",
                play: {
                  0: "<span class='badge badge-soft-danger'>Inactivo</span>",
                  1: "<span class='badge badge-soft-success'>Activo</span>",
                },
              },
            ],
            buttons: {
              edit: true,
              remove: false,
              create: false,
              width: "",
            },
            myButtons: [
              {
                text: "Productos",
                tooltip: "Lista productos asignados",
                component: "products",
                can: "change-status",
                thisTitle: "Lista productos asignados",
                scroll: true,
                modalSize: "modal-lg",
              },
            ],
            sonWindow: {
              component: null,
              row: null,
            },
          },
        },
        dataModal1: {
          slot: {
            component: null,
            data: {
              row: null,
              buttonSubmit: null,
              method: null,
            },
          },
          config: {
            id: "c-modal-1__workers-index",
            idHash: "#c-modal-1__workers-index",
          },
        },
      };
    },
    computed: {
      ...mapGetters(["url_api"]),
    },
    created() {
      this.mtd_get();
    },
    methods: {
      ...mapActions(["get", "post", "destroy", "execute_commit"]),
      ///////////////////// METODO POR DEFECTO /////////////////////
      mtd_componentOnload: function (response) {
        // eslint-disable-next-line no-undef
        $(this.dataModal1.config.idHash).modal("show");
      },

      mtd_componentSuccess: function (response) {
        if (response.component == "role-form") {
          if (response.action == "store") this.mtd_get();
          else if (response.action == "update") {
            this.execute_commit({
              mutation: "ROLE__UPDATE",
              payload: {
                data: response.role,
              },
            });
          }
          this.mtd_closeModal1();
        }
      },
      mtd_componentError: function (err) {},
      mtd_closeModal1: function () {
        // eslint-disable-next-line no-undef
        $(this.dataModal1.config.idHash).modal("hide");
        this.dataModal1.slot = {
          component: null,
          data: {
            row: null,
            buttonSubmit: null,
            method: null,
          },
        };
      },
      mtd_sonWindowClose: function () {
        this.dataTable.body.sonWindow = {
          component: null,
          row: null,
        };
      },
      mtd_nextPagination: function (page) {
        this.dataTable.header.page = page;
        this.mtd_get();
      },
      mtd_initPagination: function () {
        this.dataTable.header.page = 1;
      },

      ///////////////////// METODO PROPIOS DEL COMPONENTE /////////////////////
      //LISTAR
      mtd_get: function () {
        this.get({
          url: `${this.url_api}/workers?orderBy=name&page=${this.dataTable.header.page}&search=${this.dataTable.header.wordsearch}`,
          authorization: true,
          token: localStorage.getItem("token"),
          mutation: "WORKER__INDEX",
        })
          .then((response) => {
            //
          })
          .catch(() => {
            //
          });
      },
      //EDITAR
      mtd_edit: function (role) {
        this.dataModal1.slot.component = "role-form";
        this.dataModal1.slot.data = {
          row: role,
          buttonSubmit: "Actualizar rol",
          method: "edit",
        };
        this.dataModal1.config.scroll = true;
        this.dataModal1.config.size = "modal-md";
        this.dataModal1.config.title = "<i class='far fa-edit'></i> Editando rol";
      },
      //LISTA PRODUCTOS
      mtd_products: function (user) {
        this.dataTable.body.sonWindow = {
          component: "products-page",
          row: user,
        };
      },
    },
  };
</script>
